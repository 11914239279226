import { filterInputsList } from '../../components/Filters/filter-inputs';
import moment from 'moment';

export const SET_FILTERS = 'SET_FILTERS';

export const setFilters = (pathname) => dispatch => {
    const checkboxFilters = (pathname === '/customer-management') ? [
        {
            title: "Lock Status",
            sectionId: 'lockStatus',
            data: [
                {
                    name: "isLocked-Yes",
                    label: "Yes",
                    isChecked: false
                },
                {
                    name: 'isLocked-No',
                    label: 'No',
                    isChecked: false
                }
            ],
            hasClear: false
        },
        {
            title: "Customer Category",
            sectionId: 'customerCategory',
            data: [
                {
                    name: "fully-fledged",
                    label: "Fully-Fledged",
                    isChecked: false
                },
                {
                    name: 'limited',
                    label: 'Limited',
                    isChecked: false
                }
            ],
            hasClear: false
        },
        {
            title: "Blocked Status",
            sectionId: 'blockedStatus',
            data: [
                {
                    name: "isBlocked-Yes",
                    label: "Yes",
                    isChecked: false
                },
                {
                    name: 'isBlocked-No',
                    label: 'No',
                    isChecked: false
                }
            ],
            hasClear: false
        },
        {
            title: "Channel",
            sectionId: 'channel',
            data: [
                {
                    name: "kuik",
                    label: "KUIK",
                    isChecked: false
                },
                {
                    name: 'ron',
                    label: 'RON',
                    isChecked: false
                }
            ],
            hasClear: false
        }
    ] : [
        {
            title: "Lock Status",
            sectionId: 'lockStatus',
            data: [
                {
                    name: "isLocked-Yes",
                    label: "Yes",
                    isChecked: false
                },
                {
                    name: 'isLocked-No',
                    label: 'No',
                    isChecked: false
                }
            ],
            hasClear: false
        },
        {
            title: "Blocked Status",
            sectionId: 'blockedStatus',
            data: [
                {
                    name: "isBlocked-Yes",
                    label: "Yes",
                    isChecked: false
                },
                {
                    name: 'isBlocked-No',
                    label: 'No',
                    isChecked: false
                }
            ],
            hasClear: false
        },
        {
            title: "Subscription Type",
            sectionId: 'isSubscriptionOnline',
            data: [
                {
                    name: "true",
                    label: "Online",
                    isChecked: false
                },
                {
                    name: 'false',
                    label: 'Bank Branch',
                    isChecked: false
                }
            ],
            hasClear: false
        }
    ];


    filterInputsList.map(filterInput => {
        filterInput.filters.map(filter => {
            if (filter.type === 'date' && filter.label === 'Start Date') {
                filter.value = moment(new Date()).format('YYYY-MM-DDT00:00:00')
            } else if (filter.type === 'date' && filter.label === 'End Date') {
                filter.value = moment(new Date()).format('YYYY-MM-DDT23:59:59')
            } else if (filter.type === 'datetime-local' && filter.label === 'Start Date') {
                filter.value = moment(new Date()).format('YYYY-MM-DDT00:00:00')
            } else if (filter.type === 'datetime-local' && filter.label === 'End Date') {
                filter.value = moment(new Date()).format('YYYY-MM-DDT23:59:59')
            } else {
                filter.value = '';
            }

            if (filter.channels) {
                filter.channels.forEach(channel => channel.isClicked = false);
            }
            if (filter.qrStatus) {
                filter.qrStatus.forEach(status => status.isClicked = false);
            }
            if (filter.paymentCategory) {
                filter.paymentCategory.forEach(status => status.isClicked = false);
            }
        });

        if (filterInput.pathname === pathname) {
            dispatch({
                type: SET_FILTERS,
                payload: filterInput.filters,
                checkboxFilters: checkboxFilters
            })
        }
    })
}