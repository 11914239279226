export var reportsList = [
    {
        pathname: '/subscription-report',
        endpoint: '/report/subscription',
        reportOptions: {
            "subscribedOnStartDate": null,
            "subscribedOnEndDate": null,
            "customerNumber": null,
            "msisdn": null,
            "iban": null,
            "channel": null
        }
    },
    {
        pathname: '/subscription-status-report',
        endpoint: '/report/subscriptionstatus',
        reportOptions: {
            "modifiedOnStartDate": null,
            "modifiedOnEndDate": null,
            "customerNumber": null,
            "msisdn": null,
            "iban": null
        }
    },
    {
        pathname: '/unsubscription-report',
        endpoint: '/report/unsubscription',
        reportOptions: {
            "unsubscribedOnStartDate": null,
            "unsubscribedOnEndDate": null,
            "customerNumber": null,
            "msisdn": null,
            "iban": null
        }
    },
    {
        pathname: '/settlement-report',
        endpoint: '/report/settlement',
        reportOptions: {
            "createdOnStartDate": null,
            "createdOnEndDate": null,
            "mPayTransactionId": null,
            "rbalTransactionId": null,
            "iban": null
        }
    },
    {
        pathname: '/transaction-report',
        endpoint: '/report/transaction',
        reportOptions: {
            "createdOnStartDate": null,
            "createdOnEndDate": null,
            "kuikSubscriptionId_T1": null,
            "kuikSubscriptionId_T2": null,
            "msisdn_T1": null,
            "msisdn_T2": null,
            "iban_T1": null,
            "iban_T2": null,
            "txn_T1": null,
            "txn_T2": null,
            "channel": null,
        }
    },
    {
        pathname: '/subscription-device-report',
        endpoint: '/report/subscriptiondevice',
        reportOptions: {
            "createdOnStartDate": null,
            "createdOnEndDate": null,
            "kuikCustomerId": null,
            "msisdn": null,
            "iban": null
        }
    },
    {
        pathname: '/subscription-modification-report',
        endpoint: '/report/subscriptionmodification',
        reportOptions: {
            "modifiedOnStartDate": null,
            "modifiedOnEndDate": null,
            "kuikCustomerId": null,
            "msisdnNew": null,
            "ibanNew": null
        }
    },
    {
        pathname: '/sms-sent-report',
        endpoint: '/report/smssent',
        reportOptions: {
            "sentOnStartDate": null,
            "sentOnEndDate": null,
            "phoneNumber": null
        }
    },
    {
        pathname: '/request-money-report',
        endpoint: '/report/requestmoney',
        reportOptions: {
            "sentOnStartDate": null,
            "sentOnEndDate": null,
            "msisdn": null
        }
    },
    {
        pathname: '/topup-settlement-report',
        endpoint: '/report/topup_settlement',
        reportOptions: {
            "createdOnStartDate": null,
            "createdOnEndDate": null
        }
    },
    {
        pathname: '/topup-transaction-report',
        endpoint: '/report/topup_transaction',
        reportOptions: {
            "createdOnStartDate": null,
            "createdOnEndDate": null,
            "mPayTransactionId": null,
            "rbalTransactionId": null,
            "merchant": null,
            "iban": null,
            "msisdn": null,
            "channel": null
        }
    },
    {
        pathname: '/merchant-unsubscription-report',
        endpoint: '/merchantreports/merchantunsubscriptionreport',
        reportOptions: {
            "unsubscribedOnStartDate": null,
            "unsubscribedOnEndDate": null,
            "nuis": null,
            "ssn": null,
            "msisdn": null,
            "iban": null
        }
    },
    {
        pathname: '/merchant-subscriptions-report',
        endpoint: '/merchantreports/merchantsubscriptionsreport',
        reportOptions: {
            "subscribedOnStartDate": null,
            "subscribedOnEndDate": null,
            "personalNumber": null,
            "msisdn": null,
            "iban": null,
            "nuis": null
        }
    },
    {
        pathname: '/merchant-transactions-report',
        endpoint: '/merchantreports/merchanttransactionsreport',
        reportOptions: {
            "createdOnStartDate": null,
            "createdOnEndDate": null,
            "kuikSubscriptionId_T1": null,
            "kuikSubscriptionId_T2": null,
            "msisdn_T1": null,
            "orderId": null,
            "iban_T1": null,
            "iban_T2": null,
            "txn_T1": null,
            "txn_T2": null,
            "nuis": null
        }
    },
    {
        pathname: '/merchant-disabled-report',
        endpoint: '/merchantreports/disabledmerchantreport',
        reportOptions: {
            "subscribedOnStartDate": null,
            "subscribedOnEndDate": null,
            "personalNumber": null,
            "msisdn": null,
            "iban": null,
            "nuis": null
        }
    },
     {
        pathname: '/merchant-qr-code-report',
        endpoint: '/merchantreports/merchantqrcodestatusreport',
        reportOptions: {
            "startDate": null,
            "endDate": null,
            "orderId": null,
            "merchantNUIS": null,
            "qrStatus": null,
            "channel": null
        }
    },
    {
        pathname: '/p2b-payments-settlement-report',
        endpoint: '/merchantreports/merchantsettlementreport',
        reportOptions: {
            "createdOnStartDate": null,
            "createdOnEndDate": null,
            "paymentCategory": null,
            "mPayTransactionId": null,
            "rbalTransactionId": null,
            "iban": null
        }
    },
]
