import { SET_FILTERS } from '../actions/filters';

const initialState = {
    filters: [],
    checkboxFilters: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS:
            return {
                filters: action.payload,
                checkboxFilters: action.checkboxFilters
            };
        default:
            return state;
    }
};
