import React from 'react';
import config from '../../../config';
import Table from '../Table';
import Header from '../../Header';
import { connect } from 'react-redux';
import { setFilters } from '../../../store/actions/filters';
import { withRouter } from 'react-router-dom';

class MerchantManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 1,
      limit: 10,
      totalCount: 0,
      totalPages: 0,
      loading: false,
      filters: [],
      checkboxFilters: [],
    };
    this.onRefreshHandler = this.onRefreshHandler.bind(this);
  }

  componentDidMount() {
    this.loadData({});
    this.props.setRefresh(this.onRefreshHandler);
    this.setState({ filters: this.props.filters.filters });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filters !== this.state.filters) {
      this.setState({ filters: this.props.filters.filters });
    }

    if (prevProps.checkboxFilters !== this.props.checkboxFilters) {
      this.setState({ checkboxFilters: this.props.checkboxFilters });
    }
  }

  onChangeLimit = (event, data) => {
    if (data.value !== this.state.limit) {
      this.setState({ limit: parseInt(data.value) })
      this.setState({ page: 1 });
      this.loadData({ limit: parseInt(data.value), page: this.state.page });
    }
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state.page) {
      this.setState({ page: activePage });
      this.loadData({ page: activePage, limit: this.state.limit });
    }
  };

  getFilterValue = (position) => {
    return this.state.filters.length > 0 ? this.state.filters[position].value != '' ? this.state.filters[position].value : null : null
  }
  
  getChannelCheckbox() {
    const { checkboxFilters } = this.state;
    if (!checkboxFilters[2]) {
      return null;
    } else if (checkboxFilters[2].data[0].isChecked && !checkboxFilters[2].data[1].isChecked) {
      return true;
    } else if (checkboxFilters[2].data[1].isChecked && !checkboxFilters[2].data[0].isChecked) {
      return false;
    } else {
      return null;
    }
  }
  
  getCheckboxFilter(position, returnVal1, returnVal2) {
    const { checkboxFilters } = this.state;
    if (!checkboxFilters[position]) {
      return null;
    } else if (checkboxFilters[position].data[0].isChecked && !checkboxFilters[position].data[1].isChecked) {
      return returnVal1;
    } else if (checkboxFilters[position].data[1].isChecked && !checkboxFilters[position].data[0].isChecked) {
      return returnVal2;
    } else {
      return null;
    }
  }

  getCheckboxFilterSchema(position) {
    const { checkboxFilters } = this.state;
    if (!checkboxFilters[position]) {
      return null;
    } else {
      const selectedItem = checkboxFilters[position].data.find((item) => item.isChecked);
      return selectedItem ? selectedItem.id : null;
    }
  }
  

  loadData = params => {
    this.setState({ loading: true });
    fetch(`${config.baseURL}/merchantportal/subscribedmerchants`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify({
        "personName": this.getFilterValue(0),
        "businessName": this.getFilterValue(1),
        "nuis": this.getFilterValue(2),
        "msisdn": this.getFilterValue(3),
        "iban": this.getFilterValue(4),
        "merchantBankId": this.getFilterValue(5),
        "isSubscriptionOnline": this.getChannelCheckbox(),
        "isLocked": this.getCheckboxFilter(0, true, false),
        "isBlocked": this.getCheckboxFilter(1, true, false),
        "personalNumber": this.getFilterValue(6),
        "legalFormId": this.getFilterValue(7),
        "commissionSchemaId": this.getFilterValue(8),
        "pageIndex": params.page - 1,
        "pageSize": params.limit
      })
    }).then(response => {
      return response.json();
    }).then(data => {
        if(data.success) {
          this.setState({ data: data.value.data });
          this.setState({ totalCount: data.value.totalCount });
          this.setState({ totalPages: data.value.totalPages });
        } else {
          console.log(`Failed to load data`);
        }
        this.setState({loading: false});
    })
  };



  onRefreshHandler = () => {
    this.loadData({ page: 1 });
  }

  onResetFilters = () => {
    this.props.setFilters(this.props.location.pathname);
    this.setState({ filters: this.props.filters.filters }, this.loadData({ page: 1 }));
    this.setState({ page: 1 });
  };

  render() {
    return (
      <div>
        <Header
          filters={this.state.filters}
          loading={this.state.loading}
          onRefreshHandler={this.onRefreshHandler}
          onResetFilters={this.onResetFilters}
        />
        <Table
          onChangePage={this.onChangePage}
          onChangeLimit={this.onChangeLimit}
          data={this.state.data}
          totalCount={this.state.totalCount}
          totalPages={this.state.totalPages}
          currentPage={this.state.page}
          limit={this.state.limit.toString()}
          loading={this.state.loading}
          refreshHandler={this.onRefreshHandler}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  filters: state.filters,
  checkboxFilters: state.filters.checkboxFilters
})

export default connect(mapStateToProps, {setFilters})(withRouter(MerchantManagement));