import React from 'react';
import TableFilters from './Filters/TableFilters';
import { Divider } from 'semantic-ui-react';
import HeaderContent from "./HeaderContent";
import moment from 'moment';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [],
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filters !== this.props.filters) {
            this.setState({ filters: this.props.filters });
        }
    }

    onChannelChange = (e, data, index) => {
        const newInputs = [...this.state.filters];
        newInputs[index].channels.map(option => {
            option.isClicked = false;

            if (option.value === data.value) {
                option.isClicked = true;
            }
        });
        this.setState({ filters: newInputs });
        setTimeout(() => {
            this.props.onRefreshHandler();
        }, 0);
    }

    onQrStatusChange = (e, data, index) => {
        const newInputs = [...this.state.filters];
        newInputs[index].qrStatus.map(option => {
            option.isClicked = false;

            if (option.value === data.value) {
                option.isClicked = true;
            }
        });
        newInputs[index].value = data.value;
        this.setState({ filters: newInputs });
        setTimeout(() => {
            this.props.onRefreshHandler();
        }, 0);
    }

    onPaymentCategoryChange = (e, data, index) => {
        const newInputs = [...this.state.filters];
        newInputs[index].paymentCategory.map(option => {
            option.isClicked = false;

            if (option.value === data.value) {
                option.isClicked = true;
            }
        });
        newInputs[index].value = data.value;
        this.setState({ filters: newInputs });
        setTimeout(() => {
            this.props.onRefreshHandler();
        }, 0);
    }
    
    onChangeHandler = (e, i, ddlValue) => {
        const newInputs = [...this.state.filters];
        if(e._isAMomentObject) {
            newInputs[i].value = moment(e._d).format('YYYY-MM-DDTHH:mm:ss');
        } else if(typeof e === 'string') {
            newInputs[i].value = e;
        } else {
            if(newInputs[i].label === 'Start Date') {
                newInputs[i].value = e.target.value + 'T00:00:00';
            } else if(newInputs[i].label === 'End Date') {
                newInputs[i].value = e.target.value + 'T23:59:59';
            } else {
                newInputs[i].value = e.target.value || ddlValue;
            }
        }
        this.setState({ filters: newInputs });
        if(ddlValue) {
            setTimeout(() => {
                this.props.onRefreshHandler();
            }, 0);
        }
        document.addEventListener('keypress', (event) => {
            if (event.keyCode === 13 || event.which === 13) {
                setTimeout(() => {
                    this.props.onRefreshHandler();
                }, 0);
            }
        });
    }

    onManualDateChange = (e, i) => {
        const newInputs = [...this.state.filters];
        newInputs[i].value = e.target.value;
        this.setState({ filters: newInputs });

        document.addEventListener('keypress', (event) => {
            if (event.keyCode === 13 || event.which === 13) {
                setTimeout(() => {
                    this.onCloseHandler();
                    this.props.onRefreshHandler();
                }, 0);
            }
        });
    }

    onCloseHandler = () => {
        const newInputs = [...this.state.filters];

        newInputs.map(input => {
            if(input.type === 'datetime-local') {
                input.value = moment(input.value).format('YYYY-MM-DDTHH:mm:ss');
            }
        })

        this.setState({ filters: newInputs });
    }

    render() {
        const { filters } = this.state;

        return (
            <div>
                <HeaderContent
                    onRefreshHandler={this.props.onRefreshHandler}
                />
                <Divider />
                <TableFilters
                    filterInputs={filters}
                    onChangeHandler={this.onChangeHandler}
                    onChannelChange={this.onChannelChange}
                    onQrStatusChange={this.onQrStatusChange}
                    onPaymentCategoryChange={this.onPaymentCategoryChange}
                    onManualDateChange={this.onManualDateChange}
                    onCloseHandler={this.onCloseHandler}
                    refreshHandler={this.props.onRefreshHandler}
                    resetFilters={this.props.onResetFilters}
                    loading={this.props.loading}
                />
            </div>
        );
    }
}

export default Header;