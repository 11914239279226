import { connect } from 'react-redux';

const HavePermission = (props) => {
    const couldShow = props.userPermissions.includes(`permissions.${props.for}`);

    if (props.children) {
        return couldShow ? props.children : null;
    }
    return null;
};

const mapStateToProps = state => ({
    userPermissions: state.auth.user['claim.permission']
});

export default connect(mapStateToProps)(HavePermission);