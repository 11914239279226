import React from 'react';
import { Table } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import Helper from "../../helper";
import { customerTableHeader, merchantTableHeader } from './columns';

const TableHeader = (props) => {
    let d = props.data;
    let { pathname } = props.location;
    let content;

    if (pathname === '/customer-management') {
        content = customerTableHeader.map((item, index) => {
            return (
                <Table.HeaderCell
                    key={index}
                    width={2}
                >
                    {item.title}
                </Table.HeaderCell>
            )
        });
    } else if(pathname === '/merchant-management') {
        content = merchantTableHeader.map((item, index) => {
            return (
                <Table.HeaderCell
                    key={index}
                    width={2}
                >
                    {item.title}
                </Table.HeaderCell>
            )
        });
    } else {
        if (d[0]) {
            delete d[0].sentTime;
            delete d[0].userIp;

            if (pathname === '/subscription-device-report') {
                delete d[0].createdOnUtc;
                delete d[0].iban;
            }

            if (pathname === '/subscription-status-report' || pathname === '/subscription-report' || pathname === '/settlement-report') {
                delete d[0].category;
            }

            if (pathname === '/transaction-report') {
                delete d[0].initiatedOn;
            }

            if(pathname === '/sms-sent-report') {
                delete d[0].sourcePhone;
            }

            content = Object.keys(d[0]).map((item, index) => {
                let title;

                if (item === 'customerUsername') {
                    title = item.split(/(?=[A-Z])/).join(' ').replace('customer', 'Bank');
                } else {
                    title = item.split(/(?=[A-Z])/).join(' ').replace('customer', '');
                }

                return (
                    <Table.HeaderCell
                        key={index}
                        width={2}
                        style={(pathname === '/subscription-status-report' && item === 'subscriptionDate') || (pathname === '/transaction-report' && item === 'cbsPostingDate_T2') || (pathname === '/transaction-report' && item === 'cbsReversalDate_T2') ? { display: 'none' } : null}
                    >
                        {pathname === '/settlement-report' || pathname === '/transaction-report' || pathname === '/topup-settlement-report' ? Helper.changeTableHeaders(title) : Helper.tableHeadersFormater(pathname, title)}
                    </Table.HeaderCell>
                )
            });
        }
    }

    return (
        <Table.Header>
            <Table.Row>
                {content}
                {pathname === '/customer-management' || pathname === '/merchant-management' ? (
                    <Table.HeaderCell
                        width={1}
                    >
                        More
                    </Table.HeaderCell>
                ) : null}
            </Table.Row>
        </Table.Header>
    );
}

export default withRouter(TableHeader);