import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { CSVLink } from "react-csv";
import { csvList } from './Table/csv-list';
import Helper from '../helper';
import moment from 'moment';
import config from '../config.json';
import { connect } from 'react-redux';

class ExportData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filters: [],
            checkboxFilters: []
        }
    }

    csvLink = React.createRef()

    componentDidUpdate(prevProps) {
        if (prevProps.filters !== this.props.filters) {
            this.setState({ filters: this.props.filters.filters })
        }

        if (prevProps.checkboxFilters !== this.props.checkboxFilters) {
            this.setState({ checkboxFilters: this.props.checkboxFilters });
        }
    }

    getFilterValue = (position) => {
        return this.state.filters.length > 0 ? this.state.filters[position].value !== '' ? this.state.filters[position].value : null : null
    }

    getFilterValueForChannels = (position, channel) => {
        return this.state.filters.length > 0 && this.state.filters[position].channels !== null ?
            this.state.filters[position].channels[channel].isClicked === true ? this.state.filters[position].channels[channel].key :
                this.state.filters[position].channels[channel - 1].isClicked === true ? this.state.filters[position].channels[channel - 1].key : null : null
    }

    getCheckboxFilter(position, returnVal1, returnVal2) {
        const { checkboxFilters } = this.state;
        if (!checkboxFilters[position]) {
            return null;
        } else if (checkboxFilters[position].data[0].isChecked && !checkboxFilters[position].data[1].isChecked) {
            return returnVal1;
        } else if (checkboxFilters[position].data[1].isChecked && !checkboxFilters[position].data[0].isChecked) {
            return returnVal2;
        } else {
            return null;
        }
    }

    getChannelCheckbox() {
        const { checkboxFilters } = this.state;
        if (!checkboxFilters[3]) {
            return null;
        } else if (checkboxFilters[3].data[0].isChecked && !checkboxFilters[3].data[1].isChecked) {
            return 0;
        } else if (checkboxFilters[3].data[1].isChecked && !checkboxFilters[3].data[0].isChecked) {
            return 1;
        } else {
            return null;
        }
    }

    getChannelCheckboxMerchant() {
        const { checkboxFilters } = this.state;
        if (!checkboxFilters[2]) {
            return null;
        } else if (checkboxFilters[2].data[0].isChecked && !checkboxFilters[2].data[1].isChecked) {
            return true;
        } else if (checkboxFilters[2].data[1].isChecked && !checkboxFilters[2].data[0].isChecked) {
            return false;
        } else {
            return null;
        }
    }

    getCheckboxFilterSchema(position) {
        const { checkboxFilters } = this.state;
        if (!checkboxFilters[position]) {
            return null;
        } else {
            const selectedItem = checkboxFilters[position].data.find((item) => item.isChecked);
            return selectedItem ? selectedItem.id : null;
        }
    }

    loadData = () => {
        // const baseUrl = `${config.baseURL}/report`;
        const baseUrl = `${config.baseURL}`;
        let url;
        const baseOptions = {
            "pageIndex": 0,
            "pageSize": 10
        }

        let otherOptions = {};
        let { pathname } = this.props.location;

        csvList.map(report => {
            if (report.pathname === pathname) {
                otherOptions = report.reportOptions;
                url = baseUrl + report.endpoint;
                report.reportOptions["exportAll"] = true;
                Object.keys(report.reportOptions).map((opt, index) => {
                    if (opt !== "channel" && opt !== "exportAll") {
                        if (report.pathname === '/customer-management') {
                            if (opt === "isLimited" || opt === "isBlocked" || opt === "isLocked") {
                                report.reportOptions['isLocked'] = this.getCheckboxFilter(0, true, false);
                                report.reportOptions['isBlocked'] = this.getCheckboxFilter(2, true, false);
                                report.reportOptions['isLimited'] = this.getCheckboxFilter(1, false, true);
                            } else if (opt === 'fathersName') {
                                report.reportOptions[opt] = null;
                            } else {
                                report.reportOptions[opt] = this.getFilterValue(index);
                            }
                        } else if (report.pathname === '/merchant-management') {
                            if (opt === "isLocked" ||
                                opt === "isBlocked") {
                                report.reportOptions['isLocked'] = this.getCheckboxFilter(0, true, false);
                                report.reportOptions['isBlocked'] = this.getCheckboxFilter(1, true, false);

                            } else if (opt === "isSubscriptionOnline") {
                                report.reportOptions['isSubscriptionOnline'] = this.getChannelCheckboxMerchant();
                            } 
                            else {
                                report.reportOptions['personName'] = this.getFilterValue(0);
                                report.reportOptions['businessName'] = this.getFilterValue(1);
                                report.reportOptions['nuis'] = this.getFilterValue(2);
                                report.reportOptions['msisdn'] = this.getFilterValue(3);
                                report.reportOptions['iban'] = this.getFilterValue(4);
                                report.reportOptions['merchantBankId'] = this.getFilterValue(5);
                                report.reportOptions['legalFormId'] = this.getFilterValue(7);
                                report.reportOptions['commissionSchemaId'] = this.getFilterValue(8);
                            }
                        } else {
                            report.reportOptions[opt] = this.getFilterValue(index);
                        }
                    } else if (opt === "channel" && opt !== "exportAll") {
                        if (pathname === '/customer-management') {
                            report.reportOptions[opt] = this.getChannelCheckbox();
                        } else if (pathname === '/merchant-qr-code-report') {
                            report.reportOptions[opt] = this.getFilterValueForChannels(index, 1);
                        } else {
                            report.reportOptions[opt] = this.getFilterValueForChannels(index, 2);
                        }
                    }
                });
            }
        })
        let options = Object.assign({}, otherOptions, baseOptions);
        fetch(pathname === '/merchant-management' ? (baseUrl + "/merchantportal/subscribedmerchantsexport") : url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(options)
        }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    data.value.data.map(item => {
                        if (pathname === '/customer-management') {
                            if (item.isDeviceBlocked === true) {
                                item.isDeviceBlocked = 'Blocked';
                            } else {
                                item.isDeviceBlocked = 'Unblocked';
                            }

                            if (item.isSubscriptionLocked === true) {
                                item.isSubscriptionLocked = 'Locked';
                            } else {
                                item.isSubscriptionLocked = 'Unlocked';
                            }
                            item.address = item.address.replace(/,/g, " ");
                        }

                        if (item.category === 'fullyFledged') {
                            item.category = 'Fully-Fledged';
                        }
                        if (item.category === 'limited') {
                            item.category = 'Limited';
                        }
                        if (item.msisdn) {
                            item.msisdn = `${item.msisdn}`.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                        }

                        if (item.bankCustomerId != null)
                            item.bankCustomerId = '=""' + item.bankCustomerId + '""';
                        if (item.customerBankId != null)
                            item.customerBankId = '=""' + item.customerBankId + '""';
                        if (item.bankCustomerId_T1 != null)
                            item.bankCustomerId_T1 = '=""' + item.bankCustomerId_T1 + '""';
                        if (item.bankCustomerId_T2 != null)
                            item.bankCustomerId_T2 = '=""' + item.bankCustomerId_T2 + '""';

                    })
                    this.setState({ data: data.value.data }, () => {
                        setTimeout(() => {
                            this.csvLink.current.link.click()
                        }, 0)
                    })
                });
            } else {
                response.json().then(error => {
                    console.log(`Failed to load data: ${error.message}`);
                });
            }
        });
    }

    render() {
        let columns = [];
        let messages = [];
        let smsReportData;
        const { pathname } = this.props.location;
        const { data } = this.state;

        if (data[0]) {
            delete data[0].sentTime;
            delete data[0].userIp;

            if (pathname === '/customer-management') {
                delete data[0].bankBranchId;
                delete data[0].mobileNetworkOperatorId;
                delete data[0].id;
            }

            if (pathname === '/subscription-device-report') {
                delete data[0].createdOnUtc;
                delete data[0].iban;
            }

            if (pathname === '/subscription-status-report' || pathname === '/subscription-report') {
                delete data[0].category;
            }

            if (pathname === '/transaction-report') {
                delete data[0].initiatedOn;
            }

            if (pathname === '/sms-sent-report') {
                delete data[0].sourcePhone;
            }

            Object.keys(data[0]).map((item, index) => {
                let title;

                if (item === 'customerUsername') {
                    title = item.split(/(?=[A-Z])/).join(' ').replace('customer', 'Bank');
                } else {
                    title = item.split(/(?=[A-Z])/).join(' ').replace('customer', '');
                }

                columns.push({
                    key: item,
                    label: pathname === '/transaction-report' ? Helper.changeTableHeaders(title) : pathname === '/settlement-report' || pathname === '/topup-settlement-report' ? Helper.changeCSVHeaders(title) : Helper.tableHeadersFormater(pathname, title)
                })
            });
        }

        if (data[0] && pathname === '/sms-sent-report') {
            data.map((item) => {
                messages.push(item.message.replace(/,/g, ''));
            });

            smsReportData = data.map((el, index) => {
                var o = Object.assign({}, el);
                o.message = messages[index];
                return o;
            });
        }

        return (
            <React.Fragment>
                <Button icon positive labelPosition='left' onClick={this.loadData}>
                    <Icon name='download' />
                    Export
                </Button>

                <CSVLink
                    data={
                        pathname === '/sms-sent-report' && smsReportData ? smsReportData : data
                    }
                    headers={columns}
                    filename={pathname === '/settlement-report' ? `SettlementReport - ${moment(new Date()).format('MM.DD.YYYY')}.csv` : pathname === '/topup-settlement-report' ? `Settlement-RBAL-${moment(new Date()).format('YYYY-MM-DD')}.csv` : pathname.substring(1) + '.csv'}
                    ref={this.csvLink}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    filters: state.filters,
    checkboxFilters: state.filters.checkboxFilters
})

export default connect(mapStateToProps, null)(withRouter(ExportData));