import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Button } from 'semantic-ui-react';
import Logo from '../assets/base-kuik-logo.png';
import HavePermission from './PermissionsService/HavePermission';
import HavePermissionsGroup from './PermissionsService/HavePermissionsGroup';

const reportItems = [
    {
        title: 'Transaction Report',
        path: '/transaction-report',
        permissionName: 'specific.reports.fetch.transaction.report'
    },
    {
        title: 'Subscription Report',
        path: '/subscription-report',
        permissionName: 'specific.reports.fetch.subscription.report'
    },
    {
        title: 'Subscription Status Report',
        path: '/subscription-status-report',
        permissionName: 'specific.reports.fetch.subscription.status.report'
    },
    {
        title: 'Unsubscription Report',
        path: '/unsubscription-report',
        permissionName: 'specific.reports.fetch.unsubscription.report'
    },
    {
        title: 'Settlement Report',
        path: '/settlement-report',
        permissionName: 'specific.reports.fetch.settlement.report'
    },
    {
        title: 'Subscription Device Report',
        path: '/subscription-device-report',
        permissionName: 'specific.reports.fetch.subscription.device.report'
    },
    {
        title: 'Subscription Modification Report',
        path: '/subscription-modification-report',
        permissionName: 'specific.reports.fetch.subscription.modification.report'
    },
    {
        title: 'SMS Sent Report',
        path: '/sms-sent-report',
        permissionName: 'specific.reports.fetch.sms.sent.report'
    },
    {
        title: 'Request Money Report',
        path: '/request-money-report',
        permissionName: 'specific.reports.fetch.request.money.report'
    },
    {
        title: 'Topup Transaction Report',
        path: '/topup-transaction-report',
        permissionName: 'specific.reports.fetch.topUp.transaction.report'
    },
    {
        title: 'Topup Settlement Report',
        path: '/topup-settlement-report',
        permissionName: 'specific.reports.fetch.topUp.settlement.report'
    },
    {
        title: 'Merchant Subscriptions Report',
        path: '/merchant-subscriptions-report',
        permissionName: 'specific.reports.fetch.merchant.subscription.report'
    },
    {
        title: 'Merchant Unsubscription Report',
        path: '/merchant-unsubscription-report',
        permissionName: 'specific.reports.fetch.merchant.unsubscription.report'
    },
    {
        title: 'Merchant Transactions Report',
        path: '/merchant-transactions-report',
        permissionName: 'specific.reports.fetch.merchant.transaction.report'
    },
    {
        title: 'Merchant Disabled Report',
        path: '/merchant-disabled-report',
        permissionName: 'specific.reports.fetch.merchant.disabled.report'
    },
    {
        title: 'Merchant QR Code Report',
        path: '/merchant-qr-code-report',
        permissionName: 'specific.reports.fetch.merchant.transaction.report'
    },
    {
        title: 'P2B Payments Settlement Report',
        path: '/p2b-payments-settlement-report',
        permissionName: 'specific.reports.fetch.merchant.settlement.report'
    },
];

class Navbar extends Component {
    logOutHandler() {
        localStorage.removeItem('token');
        window.location.href = '/login';
    }

    render() {
        return (
            <header className="header-6">
                <div className="branding">
                    <Link to='/customer-management' className="nav-link">
                        <img src={Logo} alt='Logo' />
                    </Link>
                </div>
                <div className="header-nav">
                    <Link to='/dashboard' className="nav-link nav-text" onClick={this.props.addTab}>Dashboard</Link>
                    <HavePermission for="customer.management.subscription.list">
                        <Link to='/customer-management' className="nav-link nav-text" onClick={this.props.addTab}>Customer Management</Link>
                    </HavePermission>
                    <HavePermission for="merchant.management.subscription.list">
                        <Link to='/merchant-management' className="nav-link nav-text" onClick={this.props.addTab}>Merchant Management</Link>
                    </HavePermission>
                    <HavePermissionsGroup permissionsGroup={[
                        'permissions.specific.reports.fetch.transaction.report',
                        'permissions.specific.reports.fetch.subscription.report',
                        'permissions.specific.reports.fetch.subscription.status.report',
                        'permissions.specific.reports.fetch.unsubscription.report',
                        'permissions.specific.reports.fetch.settlement.report',
                        'permissions.specific.reports.fetch.subscription.device.report',
                        'permissions.specific.reports.fetch.subscription.modification.report',
                        'permissions.specific.reports.fetch.sms.sent.report',
                        'permissions.specific.reports.fetch.request.money.report',
                        'permissions.specific.reports.fetch.topUp.transaction.report',
                        'permissions.specific.reports.fetch.topUp.settlement.report',
                        "permissions.specific.reports.fetch.merchant.subscription.report",
                        "permissions.specific.reports.fetch.merchant.unsubscription.report",
                        "permissions.specific.reports.fetch.merchant.transaction.report"
                    ]}>
                        <Dropdown text='Reports' className="nav-link nav-text">
                            <Dropdown.Menu>
                                {reportItems.map(item => (
                                    <HavePermission key={item.path} for={item.permissionName}>
                                        <Link to={item.path}><Dropdown.Item text={item.title} onClick={this.props.addTab} /></Link>
                                        {item.path === '/request-money-report' || item.path === '/topup-settlement-report' ? <Dropdown.Divider /> : null}
                                    </HavePermission>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </HavePermissionsGroup>
                </div>
                <div className="header-actions">
                    <Dropdown icon='user circle' className="nav-link nav-icon" direction='left'>
                        <Dropdown.Menu direction='left' className='user-dropdown'>
                            <div>{this.props.user['claim.identifier']}</div>
                            <Button size='mini' primary onClick={this.logOutHandler}>Log out</Button>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </header>
        );
    }
}

export default Navbar;