import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

const Dialog = props => {
    return (
            <Modal
                style={props.style}
                className={'mini-modal'}
                size={'small'}
                open={props.open}
            >
                <Modal.Header>{props.title}</Modal.Header>
                <Modal.Content>
                    {props.children}
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={props.onCancel}>
                        Cancel
                    </Button>
                    <Button primary loading={props.loading} onClick={props.onConfirm}>
                        {props.confirmText}
                    </Button>
                </Modal.Actions>
            </Modal>
    );
}
export default Dialog;