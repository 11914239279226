import React from 'react';

const Checkbox = props => {
    return (
        <div className="checkbox">
            <input name={props.name} type="checkbox" id={`checkbox-${props.name}`} checked={props.checked || false} onChange={props.onChange} />
            <label name={props.name} htmlFor={`checkbox-${props.name}`}>{props.label}</label>
        </div>
    );
}

export default Checkbox;