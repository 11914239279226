import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Modal, Step } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import config from '../../config';
import { toast } from 'react-toastify';
import StepsForMerchant from '../UI/StepsForMerchant';

const MerchantSubscriptionModal = props => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [mnos, setMNOS] = useState([]);
    const [bankBranch, setBankBranch] = useState([]);
    const [selectedMNOID, setSelectedMNOID] = useState();
    const [selectedBankBranch, setSelectedBankBranch] = useState();
    const [mnoValue, setMnoValue] = useState('Mobile Operator');
    const [bankBranchValue, setBankBranchValue] = useState('Bank Branch');
    const [legalForm, setLegalForm] = useState([]);
    const [commissionSchema, setCommissionSchema] = useState([]);
    const [step, setStep] = useState(1);

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const closeModal = () => {
        setOpen(false);
    }

    useEffect(() => {
        if (!open) {
            setStep(1);
        }
    }, [open]);

    const getMNOList = () => {
        fetch(`${config.baseURL}/admin/mobilenetworkoperator/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10000
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setMNOS(data.value.data);
        })
    };

    const getBankBranchList = () => {
        fetch(`${config.baseURL}/admin/bankbranch/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10000
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setBankBranch(data.value.data);
        })
    };

    const getSchemaList = () => {
        fetch(`${config.baseURL}/admin/commissionschema/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10000
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setCommissionSchema(data.value.data);
        })
    };

    const getFormsList = () => {
        fetch(`${config.baseURL}/admin/legalform/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10000
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setLegalForm(data.value.data);
        })
    };

    const onModalOpen = () => {
        setOpen(true);
        getMNOList();
        getBankBranchList();
        getSchemaList();
        getFormsList();
    }

    return (
        <Modal className={step === 1 ? 'regular-modal step-1' : step === 2 ? 'regular-modal step-2' : 'regular-modal'} onClose={() => setOpen(false)}
            onOpen={onModalOpen} trigger={props.trigger} open={open}>
            <Modal.Header>{props.header}</Modal.Header>
            <Modal.Content>
                <Step.Group ordered>
                    <Step active={step === 1 ? true : false} completed={step > 1 ? true : false}>
                        <Step.Content>
                            <Step.Title>Merchant</Step.Title>
                        </Step.Content>
                    </Step>

                    <Step active={step === 2 ? true : false} completed={step > 2 ? true : false}>
                        <Step.Content>
                            <Step.Title>IBAN</Step.Title>
                        </Step.Content>
                    </Step>
                </Step.Group>
                <StepsForMerchant
                    step={step}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    closeModal={closeModal}
                    refreshHandler={props.refreshHandler}
                    mnos={mnos}
                    bankBranch={bankBranch}
                    commissionSchema={commissionSchema}
                    legalForm={legalForm}
                />
            </Modal.Content>
        </Modal>
    );
};

export default MerchantSubscriptionModal;