export const customerTableHeader = [
    {
        title: 'Customer Bank ID'
    },
    {
        title: 'Personal number'
    },
    {
        title: 'Full name'
    },
    {
        title: 'Father name'
    },
    {
        title: 'MSISDN'
    },
    {
        title: 'MNO'
    },
    {
        title: 'Subscription type'
    },
    {
        title: 'Category'
    },
    {
        title: 'Lock status'
    },
    {
        title: 'Is blocked'
    },
    {
        title: 'Channel'
    }
];

export const merchantTableHeader = [
    {
        title: 'Merchant Bank ID'
    },
    {
        title: 'Business Name'
    },
    {
        title: 'Merchant Trade Name'
    },
    {
        title: 'NUIS'
    },
    {
        title: 'IBAN'
    },
    {
        title: 'Leg.Rep.Fullname'
    },
    {
        title: 'Leg.Rep.MSISDN'
    },
    {
        title: 'Legal.Rep.SSN'
    },
    {
        title: 'Legal Form'
    },
    {
        title: 'Commission Schema'
    },
    {
        title: 'Subscription type'
    },
    {
        title: 'Lock status'
    },
    {
        title: 'Is blocked'
    },
    {
        title: 'Channel'
    }
];
