import React from 'react';

const FilterItem = props => {
    return (
        <section className="nav-group collapsible">
            <input id={props.title} type="checkbox" />
            <label htmlFor={props.title}>{props.title}</label>
            {props.hasClear ? <a href='javascript://' style={{ marginLeft: 40 }} onClick={props.clearHandler}>Clear</a> : ''}
            <ul className="nav-list">
                {props.children}
            </ul>
        </section>
    );
}

export default FilterItem;