import React, { Fragment } from 'react';

const Input = props => {
    return (
        <Fragment>
            <label>{props.label}</label>
            <input type={props.type} name={props.name} placeholder={props.placeholder} style={input} onChange={props.onChange} value={props.value} />
        </Fragment>
    );
};

const input = {
    borderRadius: 0,
    fontSize: '12px',
};

export default Input;