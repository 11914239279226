import React, { useState, useEffect } from 'react';
import config from '../../config';
import { withRouter } from 'react-router-dom';
import { Table, Dropdown, Select, Form, Modal, Button } from 'semantic-ui-react';
import Dialog from '../UI/Dialog';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import HavePermission from '../PermissionsService/HavePermission';

const TableRow = props => {
    const { data } = props;
    const { pathname } = props.location;
    const [editedIban, setIban] = useState(data.iban);
   
    const [customerData, setCustomerData] = useState({
        editedID: data.id,
        editedName: data.fullName,
        editedCustomerBankID: data.customerBankId,
        editedFathersName: data.fathersName,
        editedMsisdn: data.msisdn,
        editedPersonalNumber: data.personalNumber,
        editedBirthdate: data.birthdate,
        editedAddress: data.address,
        editedCity: data.city,
        editedEmail: data.email,
        editedDescription: data.description,
        editedNuis: data.nuis,
        editedUsername: data?.username,
        editedBusinessName: data.businessName,
        editedMerchantTradeName: data.merchantTradeName,
    });
    const [open, setOpen] = useState(false);
    const [openLock, setOpenLock] = useState(false);
    const [openLockMerchant, setOpenLockMerchant] = useState(false);
    const [openBlock, setOpenBlock] = useState(false);
    const [openBlockMerchant, setOpenBlockMerchant] = useState(false);
    const [openUpgrade, setOpenUpgrade] = useState(false);
    const [openUnsub, setOpenUnsub] = useState(false);
    const [openIbanPopup, setOpenIbanPopup] = useState(false);
    const [openMsisdnPopup, setOpenMsisdnPopup] = useState(false);
    const [openIbanPopupForMerchant, setOpenIbanPopupForMerchant] = useState(false);
    const [openChangePassword, setOpenChangePassword] = useState(false);

    const [lock, setLock] = useState(pathname === '/customer-management' ? data.isSubscriptionLocked : data.isLocked);
    const [block, setBlock] = useState(data.isDeviceBlocked);
    const [reason, setReason] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [confirmed, setConfirmed] = useState(false);

    const [mnos, setMNOS] = useState([]);
    const [selectedMNOID, setSelectedMNOID] = useState(data.mobileNetworkOperatorId);
    const [bankBranch, setBankBranch] = useState([]);
    const [commissionSchema, setCommissionSchema] = useState([]);
    const [legalForm, setLegalForm] = useState([]);

    const [selectedBankBranch, setSelectedBankBranch] = useState(data.bankBranchId);
    const [selectedFormID, setSelectedFormID] = useState(data.legalFormId);
    const [selectedSchemaID, setSelectedSchemaID] = useState(data.commissionSchemaId);
    const [unsubReasons, setUnsubReasons] = useState([]);

    const [isMerchant, setIsMerchant] = useState(false);

    const customerTableRows = [
        {
            value: data.customerBankId
        },
        {
            value: data.personalNumber
        },
        {
            value: data.name
        },
        {
            value: data.fathersName
        },
        {
            value: data.msisdn
        },
        {
            value: data.mobileNetworkOperatorName
        },
        {
            value: data.subscriptionType
        },
        {
            value: data.category
        },
        {
            value: data.isSubscriptionLocked ? "Locked" : "Unlocked"
        },
        {
            value: data.isDeviceBlocked ? "Blocked" : "Unblocked"
        },
        {
            value: data.channel
        }
    ];

    const merchantTableRows = [
        {
            value: data.merchantBankId
        },
        {
            value: data.businessName
        },
        {
            value: data.merchantTradeName
        },
        {
            value: data.nuis
        },
        {
            value: data.iban
        },
        {
            value: data.fullName
        },
        {
            value: data.msisdn
        },
        {
            value: data.personalNumber
        },
        {
            value: data.legalForm
        },
        {
            value: data.commissionSchemaName
        },
        {
            value: data.subscriptionType
        },
        {
            value: data.isLocked ? "Locked" : "Unlocked"
        },
        {
            value: data.isBlocked ? "Blocked" : "Unblocked"
        },
        {
            value: data.channel
        },
    ];

    const displayLockText = data.isLocked || data.isSubscriptionLocked ? 'Unlock' : 'Lock';
    const displayBlockText = data.isDeviceBlocked ? 'Unblock' : 'Block';

    useEffect(() => {
        setIban(data.iban);
        setCustomerData({
            editedID: data.id,
            editedName: data?.fullName,
            editedCustomerBankID: data.customerBankId,
            editedFathersName: data.fathersName,
            editedMsisdn: data?.msisdn,
            editedPersonalNumber: data?.personalNumber,
            editedBirthdate: data.birthdate,
            editedAddress: data.address,
            editedCity: data.city,
            editedEmail: data.email,
            editedDescription: data.description,
            editedNuis: data.nuis,
            editedUsername: data?.username,
            editedBusinessName: data?.businessName,
            editedMsisdn: data?.msisdn,
            editedMerchantTradeName: data.merchantTradeName,
        });
        setLock(pathname === '/customer-management' ? data.isSubscriptionLocked : data.isLocked);
        setBlock(data.isDeviceBlocked);
    }, [data]);

    const lockHandler = () => {
        setLoading(true);
        const requestBody = {
            [openLockMerchant ? 'merchantId' : 'customerId']: props.data.id
        };
        fetch(`${config.baseURL}${openLockMerchant ? '/merchantportal/lockunlockmerchant' : '/customers/lock-unlock'}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(requestBody)
        }).then(res => {
            return res.json();
        }).then(data => {
            setLoading(false);
            toast.success(lock ? 'Unlocked Successfully!' : 'Locked Successfully!');
            setOpenLock(false);
            setOpenLockMerchant(false);
            props.refreshHandler();
        });
    };

    const blockHandler = () => {
        const requestBody = {
            [openBlockMerchant ? 'merchantId' : 'customerId']: props.data.id
        };
        setLoading(true);
        fetch(`${config.baseURL}${openBlockMerchant ? '/merchantportal/blockunblockmerchant' : '/customers/block-unblock'}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(requestBody)
        }).then(res => {
            return res.json();
        }).then(data => {
            setLoading(false);
            setBlock(block => !block);
            props.refreshHandler();
            toast.success(block ? 'Unblocked Successfully!' : 'Blocked Successfully!');
            setOpenBlock(false);
            setOpenBlockMerchant(false);
        })
    };

    const resetPinHandler = () => {
        setLoading(true);
        fetch(`${config.baseURL}/customers/reset-pin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                customerId: props.data.id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            if (data.success) {
                setLoading(false);
                localStorage.setItem('activationcode', data.value.activationCode);
                localStorage.setItem('name', data.value.name);
                localStorage.setItem('personalnumber', data.value.personalNumber);
                localStorage.setItem('mobile', data.value.msisdn);
                localStorage.setItem('email', data.value.email);
                localStorage.setItem('address', data.value.address);
                localStorage.setItem('iban', data.value.iban);
                window.open('/form/resetpinform.html', "_blank");
                toast.success('PIN Resetted Successfully');
                setOpen(false);
                props.refreshHandler();
            } else {
                setLoading(false);
                toast.error(data.messages[0].text);
                setOpen(false);
            }
        })
    };

    const upgradeHandler = () => {
        setLoading(true);
        fetch(`${config.baseURL}/customers/upgrade`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                customerId: props.data.id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            if (data.success) {
                const dateString = data?.value?.birthDate;
                const dateObj = new Date(dateString);

                const day = String(dateObj.getDate()).padStart(2, '0');
                const month = String(dateObj.getMonth() + 1).padStart(2, '0');
                const year = dateObj.getFullYear();
                setLoading(false);
                localStorage.setItem('name', data.value.name);
                localStorage.setItem('birthday', `${day}-${month}-${year}`);
                localStorage.setItem('personalnumber', data.value.personalNumber);
                localStorage.setItem('mobile', data.value.msisdn);
                localStorage.setItem('email', data.value.email);
                localStorage.setItem('address', data.value.address);
                localStorage.setItem('iban', data.value.iban);
                window.open('/form/upgradeform.html', "_blank");
                toast.success('Customer Upgraded Successfully');
                setOpenUpgrade(false);
                props.refreshHandler();
            } else {
                setLoading(false);
                toast.error(data.messages[0].text);
                setOpenUpgrade(false);
            }
        })
    };

    const getUnsubscriptionReasons = () => {
        fetch(`${config.baseURL}/admin/unsubscriptionreason/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10000
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setUnsubReasons(data.value.data);
        })
    };

    const unsubscribeHandler = () => {
        const endpoint = isMerchant ? 'merchantportal/merchant-unsubscribe' : 'customers/unsubscribe';
        const body = isMerchant ? {
            merchantId: props.data.id,
            reasonCode: reason
        } : {
            customerId: props.data.id,
            reasonCode: reason
        }

        setLoading(true);
        fetch(`${config.baseURL}/${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(body)
        }).then(res => {
            return res.json();
        }).then(data => {
            if (data.success) {
                const dateString = data?.value?.birthDate;
                const dateObj = new Date(dateString);

                const day = String(dateObj.getDate()).padStart(2, '0');
                const month = String(dateObj.getMonth() + 1).padStart(2, '0');
                const year = dateObj.getFullYear();
                localStorage.setItem('name', data.value.name);
                localStorage.setItem('birthday', `${day}-${month}-${year}`);
                localStorage.setItem('personalnumber', data.value.personalNumber);
                localStorage.setItem('merchantTradeName', data?.value?.merchantTradeName);
                localStorage.setItem('mobile', data.value.msisdn);
                localStorage.setItem('email', data.value.email);
                localStorage.setItem('address', data.value.address);
                localStorage.setItem('legalForm', data.value.legalForm);
                localStorage.setItem('businessName', data?.value?.businessName);
                localStorage.setItem('iban', data.value.iban);
                localStorage.setItem('nuis', data.value.nuis);
                localStorage.setItem('reason', data.value.reason);
                isMerchant ? window.open('/form/unsubformMerchant.html', "_blank") : window.open('/form/unsubform.html', "_blank");
                toast.success(`${isMerchant ? 'Merchant' : 'Customer'} Unsubscribed Successfully`);
                setOpenUnsub(false);
                props.refreshHandler();
            } else {
                toast.error(data.messages[0].text);
            }
        });

        setLoading(false);
        setIsMerchant(false);
    };

    const syncIbanHandler = () => {
        setError(null);
        setConfirmed(false);
        setLoading(true);
        fetch(`${config.baseURL}/customers/sync-iban`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                iban: editedIban,
                id: customerData.editedID,
                name: customerData.editedName,
                customerBankId: customerData.editedCustomerBankID,
                fathersName: customerData.editedFathersName,
                msisdn: customerData.editedMsisdn,
                personalNumber: customerData.editedPersonalNumber,
                birthdate: customerData.editedBirthdate,
                address: customerData.editedAddress,
                city: customerData.editedCity,
                email: customerData.editedEmail
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            if (data.success) {
                setLoading(false);
                setConfirmed(true);
                setCustomerData({
                    editedID: data.value.id,
                    editedName: data.value.name,
                    editedCustomerBankID: data.value.customerBankId,
                    editedFathersName: data.value.fathersName,
                    editedMsisdn: data.value.msisdn,
                    editedPersonalNumber: data.value.personalNumber,
                    editedBirthdate: data.value.birthdate,
                    editedAddress: data.value.address,
                    editedCity: data.value.city,
                    editedEmail: data.value.email,
                    editedMerchantTradeName: data.value.merchantTradeName,
                })
            } else {
                setError(data.messages[0].text);
                setLoading(false);
            }
        })
    };

    const editIbanHandler = (userData) => {
        setLoading(true);
        fetch(`${config.baseURL}/customers/change-iban`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                customerId: data.id,
                iban: userData.iban,
                fatherName: userData.fatherName,
                birthDate: userData.birthDate,
                address: userData.address,
                city: userData.city,
                email: userData.email,
                bankBranchId: selectedBankBranch,
                mobileNetworkOperatorId: selectedMNOID,
                accountDescription: userData.description
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            if (data.success) {
                const dateString = data?.value?.birthDate;
                const dateObj = new Date(dateString);

                const day = String(dateObj.getDate()).padStart(2, '0');
                const month = String(dateObj.getMonth() + 1).padStart(2, '0');
                const year = dateObj.getFullYear();

                localStorage.setItem('name', data.value.name);
                localStorage.setItem('birthday', `${day}-${month}-${year}`);
                localStorage.setItem('personalnumber', data.value.personalNumber);
                localStorage.setItem('mobile', data.value.msisdn);
                localStorage.setItem('email', data.value.email);
                localStorage.setItem('address', data.value.address);
                localStorage.setItem('iban', data.value.iban);
                localStorage.setItem('activationcode', data.value.activationCode);
                setOpenIbanPopup(false);
                setLoading(false);
                props.refreshHandler();
                window.open('/form/modificationform.html', "_blank");
                toast.success('IBAN Changed Successfully!');
            } else {
                toast.error(data.messages[0].text);
                setLoading(false);
            }
        });
    };

    const editHandlerForMerchant = (userData) => {
        setLoading(true);
        fetch(`${config.baseURL}/merchantportal/editmerchant`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                merchantId: data.id,
                name: userData.fullName,
                personalNumber: userData.personalNumber,
                msisdn: userData.msisdn,
                commissionSchemaId: selectedSchemaID,
                legalFormId: selectedFormID,
                businessName: userData.businessName,
                merchantTradeName: userData.merchantTradeName,
                username: userData.username,
                nuis: userData.nuis,
                iban: userData.iban,
                birthDate: userData.birthDate,
                address: userData.address,
                email: userData.email,
                bankBranchId: selectedBankBranch,
                mobileNetworkOperatorId: selectedMNOID
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            if (data.success) {
                const dateString = data.value.birthDate;
                const dateObj = new Date(dateString);

                const day = String(dateObj.getDate()).padStart(2, '0');
                const month = String(dateObj.getMonth() + 1).padStart(2, '0');
                const year = dateObj.getFullYear();

                localStorage.setItem('name', data.value.name);
                localStorage.setItem('birthday', `${day}-${month}-${year}`);
                localStorage.setItem('personalnumber', data.value.personalNumber);
                localStorage.setItem('merchantTradeName', data.value.merchantTradeName);
                localStorage.setItem('mobile', data.value.msisdn);
                localStorage.setItem('email', data.value.email);
                localStorage.setItem('address', data.value.address);
                localStorage.setItem('legalForm', data.value.legalForm);
                localStorage.setItem('businessName', data.value.businessName);
                localStorage.setItem('iban', data.value.iban);
                localStorage.setItem('nuis', data.value.nuis);
                localStorage.setItem('activationcode', data.value.activationPassword);

                localStorage.setItem('isNameChanged', data.value.isNameChanged);
                localStorage.setItem('isBirthdateChanged', data.value.isBirthdateChanged);
                localStorage.setItem('isPersonalNumberChanged', data.value.isPersonalNumberChanged);
                localStorage.setItem('isMsisdnChanged', data.value.isMsisdnChanged);
                localStorage.setItem('isEmailChanged', data.value.isEmailChanged);
                localStorage.setItem('isAddressChanged', data.value.isAddressChanged);
                localStorage.setItem('isIbanChanged', data.value.isIbanChanged);
                localStorage.setItem('isUsernameChanged', data.value.isUsernameChanged);
                localStorage.setItem('isBusinessNameChanged', data.value.isBusinessNameChanged);
                localStorage.setItem('isMerchantTradeNameChanged', data.value.isMerchantTradeNameChanged);
                localStorage.setItem('isNUISChanged', data.value.isNUISChanged);
                localStorage.setItem('isLegalFormChanged', data.value.isLegalFormChanged);
                setOpenIbanPopupForMerchant(false);
                setLoading(false);
                props.refreshHandler();
                window.open('/form/merchantmodification.html', "_blank");
                toast.success('Merchant Changed Successfully!');
            } else {
                toast.error(data.messages[0].text);
                setLoading(false);
            }
        });
    };

    const editMsisdnHandler = (userData) => {
        setLoading(true);
        fetch(`${config.baseURL}/customers/change-msisdn`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                customerId: props.data.id,
                msisdn: userData.msisdn,
                fatherName: userData.fatherName,
                birthDate: userData.birthDate,
                address: userData.address,
                city: userData.city,
                email: userData.email,
                mobileOperatorId: selectedMNOID,
                bankBranchId: selectedBankBranch,
                accountDescription: userData.description,
                merchantTradeName: userData.merchantTradeName
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            if (data.success) {
                localStorage.setItem('name', data.value.name);
                localStorage.setItem('birthday', data.value.birthDate);
                localStorage.setItem('personalnumber', data.value.personalNumber);
                localStorage.setItem('mobile', data.value.msisdn);
                localStorage.setItem('email', data.value.email);
                localStorage.setItem('address', data.value.address);
                localStorage.setItem('iban', data.value.iban);
                localStorage.setItem('activationcode', data.value.activationCode);
                setOpenMsisdnPopup(false);
                setLoading(false);
                props.refreshHandler();
                window.open('/form/modificationform.html', "_blank");
                toast.success('MSISDN Changed Successfully!');
            } else {
                toast.error(data.messages[0].text);
                setLoading(false);
            }
        })
    };

    const merchantChangePassword = () => {
        setLoading(true);
        fetch(`${config.baseURL}/merchantportal/merchant-reset-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                merchantId: props.data.id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            if (data.success) {
                const dateString = data?.value?.birthDate;
                const dateObj = new Date(dateString);

                const day = String(dateObj.getDate()).padStart(2, '0');
                const month = String(dateObj.getMonth() + 1).padStart(2, '0');
                const year = dateObj.getFullYear();
                setLoading(false);
                localStorage.setItem('name', data.value.name);
                localStorage.setItem('birthday', `${day}-${month}-${year}`);
                localStorage.setItem('personalnumber', data.value.personalNumber);
                localStorage.setItem('merchantTradeName', data.value.merchantTradeName);
                localStorage.setItem('mobile', data.value.msisdn);
                localStorage.setItem('email', data.value.email);
                localStorage.setItem('address', data.value.address);
                localStorage.setItem('legalForm', data.value.legalForm);
                localStorage.setItem('businessName', data.value.businessName);
                localStorage.setItem('iban', data.value.iban);
                localStorage.setItem('nuis', data.value.nuis);
                localStorage.setItem('activationcode', data.value.activationPassword);
                setOpenChangePassword(false);
                props.refreshHandler();
                window.open('/form/merchantresetpasswordform.html', "_blank");
                toast.success('Password Resetted Successfully!');
            } else {
                setLoading(false);
                toast.error(data.messages[0].text);
                setOpenChangePassword(false);
            }
        })
    };

    const getMNOList = () => {
        fetch(`${config.baseURL}/admin/mobilenetworkoperator/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10000
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setMNOS(data.value.data);
        })
    };

    const getBankBranchList = () => {
        fetch(`${config.baseURL}/admin/bankbranch/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10000
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setBankBranch(data.value.data);
        })
    };

    const getFormsList = () => {
        fetch(`${config.baseURL}/admin/legalform/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10000
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setLegalForm(data.value.data);
        })
    };

    const getSchemaList = () => {
        fetch(`${config.baseURL}/admin/commissionschema/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10000
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setCommissionSchema(data.value.data);
        })
    };

    const onSelectMNOHandler = (e, data) => {
        mnos.map(mno => {
            if (mno.name === data.value) {
                setSelectedMNOID(mno.indexId);
            }
        })
    }

    const onSelectBranchHandler = (e, data) => {
        bankBranch.map(branch => {
            if (branch.displayName === data.value) {
                setSelectedBankBranch(branch.indexId);
            }
        })
    }

    const onSelectLegalFormHandler = (e, data) => {
        legalForm.map(form => {
            if (form.name === data.value) {
                return setSelectedFormID(form.indexId);
            }
        })
    }

    const onSelectSchemaHandler = (e, data) => {
        commissionSchema.map(schema => {
            if (schema.name === data.value) {
                return setSelectedSchemaID(schema.indexId);
            }
        })
    }

    const cellStyleConditioner = (index) => {
        if ((pathname === '/subscription-status-report' && index === 17) || (pathname === '/transaction-report' && index === 30) || (pathname === '/transaction-report' && index === 31)) {
            return {
                display: 'none'
            };
        }
    };

    let content;

    if (pathname === '/customer-management') {
        content = customerTableRows.map((item, index) => {
            return (
                <Table.Cell key={index}>{item.value}</Table.Cell>
            );
        });
    } else if (pathname === '/merchant-management') {
        content = merchantTableRows.map((item, index) => {
            return (
                <Table.Cell key={index}>{item.value}</Table.Cell>
            );
        });
    } else {
        delete props.data.sentTime;
        delete props.data.userIp;

        if (pathname === '/subscription-device-report') {
            delete props.data.createdOnUtc;
            delete props.data.iban;
        }

        if (pathname === '/subscription-status-report' || pathname === '/subscription-report' || pathname === '/settlement-report') {
            delete props.data.category;
        }

        if (pathname === '/transaction-report') {
            delete props.data.initiatedOn;
        }

        if (pathname === '/sms-sent-report') {
            delete props.data.sourcePhone;
        }

        content = Object.values(props.data).map((item, index) => {
            return (
                <Table.Cell
                    key={index}
                    style={cellStyleConditioner(index)}
                >
                    {item === 'fullyFledged' ? 'Fully-Fledged' : item === 'limited' ? 'Limited' : item}
                </Table.Cell>
            );
        });
    };

    return (
        <React.Fragment>
            <Table.Row onClick={props.onClick} style={props.style}>
                {content}
                {pathname === '/customer-management' && (
                    <Table.Cell>
                        <Dropdown pointing icon='ellipsis vertical'>
                            <Dropdown.Menu style={dropdownStyle}>
                                {data.channel !== "RON" && (
                                    <React.Fragment>
                                        <HavePermission for="customer.management.edit.iban">
                                            <Dropdown.Item style={itemStyle} onClick={() => {
                                                setOpenIbanPopup(true);
                                                getMNOList();
                                                getBankBranchList();
                                            }}>Edit IBAN</Dropdown.Item>
                                        </HavePermission>
                                        <HavePermission for="customer.management.edit.msisdn">
                                            <Dropdown.Item style={itemStyle} onClick={() => {
                                                setOpenMsisdnPopup(true);
                                                getMNOList();
                                                getBankBranchList();
                                            }}>Edit MSISDN</Dropdown.Item>
                                        </HavePermission>
                                        <HavePermission for="customer.management.upgrade.subscription">
                                            <Dropdown.Item style={itemStyle} onClick={() => setOpenUpgrade(true)}>Upgrade</Dropdown.Item>
                                        </HavePermission>
                                        <HavePermission for="customer.management.reset.customer.pin">
                                            <Dropdown.Item style={itemStyle} onClick={() => setOpen(true)}>Reset PIN</Dropdown.Item>
                                        </HavePermission>
                                        <HavePermission for="customer.management.lock/unlock.subscription">
                                            <Dropdown.Item style={itemStyle} onClick={() => { setOpenLock(true) }}>{displayLockText}</Dropdown.Item>
                                        </HavePermission>
                                        <HavePermission for="customer.management.block/unblock.subscription">
                                            <Dropdown.Item style={itemStyle} onClick={() => setOpenBlock(true)}>{displayBlockText}</Dropdown.Item>
                                        </HavePermission>
                                    </React.Fragment>
                                )}
                                <HavePermission for="customer.management.unsubscribe">
                                    <Dropdown.Item style={itemStyle} onClick={() => {
                                        setOpenUnsub(true);
                                        getUnsubscriptionReasons();
                                    }}>Unsubscribe</Dropdown.Item>
                                </HavePermission>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Table.Cell>
                )}

                {pathname === '/merchant-management' && (
                    <Table.Cell>
                        <Dropdown pointing icon='ellipsis vertical'>
                            <Dropdown.Menu style={dropdownStyle}>
                                <Dropdown.Item style={itemStyle} onClick={() => {
                                    setOpenIbanPopupForMerchant(true);
                                    getMNOList();
                                    getBankBranchList();
                                    getFormsList();
                                    getSchemaList();
                                }}>Edit Merchant</Dropdown.Item>
                                <Dropdown.Item style={itemStyle} onClick={() => {
                                    setOpenChangePassword(true);
                                }}>Reset Password</Dropdown.Item>
                                <HavePermission for="merchant.management.unsubscribe">
                                    <Dropdown.Item style={itemStyle} onClick={() => {
                                        setOpenUnsub(true);
                                        getUnsubscriptionReasons();
                                        setIsMerchant(true);
                                    }}>Unsubscribe</Dropdown.Item>
                                </HavePermission>
                                <HavePermission for="merchant.management.lock/unlock.merchant">
                                    <Dropdown.Item style={itemStyle} onClick={() => {
                                        setOpenLock(true);
                                        setOpenLockMerchant(true);
                                    }
                                    }>{displayLockText}</Dropdown.Item>
                                </HavePermission>
                                <HavePermission for="merchant.management.block/unblock.merchant">
                                    <Dropdown.Item style={itemStyle} onClick={() => {
                                        setOpenBlock(true);
                                        setOpenBlockMerchant(true);
                                    }
                                    }>{displayBlockText}</Dropdown.Item>
                                </HavePermission>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Table.Cell>
                )}
            </Table.Row>

            <Dialog
                open={open}
                onCancel={() => { setOpen(false) }}
                onConfirm={resetPinHandler}
                title={'Reset PIN'}
                confirmText={'Reset Pin'}
                loading={loading}
            ><p>Are you sure to Reset PIN for selected customer?</p></Dialog>

            <Dialog
                open={openLock}
                onCancel={() => { setOpenLock(false) }}
                onConfirm={lockHandler}
                title={openLockMerchant ? 'Merchant' : ' Customer'}
                confirmText={displayLockText}
                loading={loading}
            ><p>Are you sure to {displayLockText} the selected {openLockMerchant ? 'merchant' : ' customer'}?</p></Dialog>

            <Dialog
                open={openBlock}
                onCancel={() => { setOpenBlock(false) }}
                onConfirm={blockHandler}
                title={displayBlockText + openBlockMerchant ? 'Merchant' : ' Customer'}
                confirmText={displayBlockText}
                loading={loading}
            ><p>Are you sure to {displayBlockText} the selected {openBlockMerchant ? 'merchant' : ' customer'}?</p></Dialog>

            <Dialog
                open={openUpgrade}
                onCancel={() => { setOpenUpgrade(false) }}
                onConfirm={upgradeHandler}
                title={'Upgrade Customer'}
                confirmText={'Upgrade'}
                loading={loading}
            ><p>Are you sure to Upgrade for selected customer?</p></Dialog>

            <Modal
                open={openUnsub}
                className={'regular-modal unsub-modal'}
                size={'small'}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={{ reason: '' }}
                    onSubmit={values => {
                        unsubscribeHandler();
                    }}
                    validationSchema={Yup.object().shape({
                        reason: Yup.string()
                            .required('This field is required.')
                            .trim()
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <React.Fragment>
                            <Modal.Header>Unsubscribe</Modal.Header>
                            <Modal.Content>
                                <div>
                                    <p>Please select unsubscribe reason for the list.</p>
                                    <Form.Field>
                                        <select
                                            name='reason'
                                            value={values.reason}
                                            onChange={(e) => {
                                                handleChange(e);
                                                unsubReasons.map(reason => {
                                                    if (reason.reason === e.target.value) {
                                                        setReason(reason.indexId)
                                                    }
                                                });
                                            }}
                                            onBlur={handleBlur}
                                            style={{ borderRadius: 0, minHeight: 50, paddingTop: 5, paddingLeft: 10, marginTop: 10, width: '100%' }}>
                                            <option value=''>Unsubscribe Reason</option>
                                            {unsubReasons.map(el => {
                                                return <option value={el.reason} key={el.indexId}>{el.reason}</option>
                                            })}
                                        </select>
                                        {errors.reason && touched.reason ? (
                                            <span style={{ fontSize: 13, color: '#078ec9' }}>{errors.reason}</span>
                                        ) : null}
                                    </Form.Field>
                                </div>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative onClick={() => { setOpenUnsub(false); setIsMerchant(false); }}>
                                    Cancel
                                </Button>
                                <Button disabled={!reason || errors.reason} loading={loading} primary onClick={handleSubmit}>
                                    Unsubscribe
                                </Button>
                            </Modal.Actions>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>

            <Modal
                className={'large-modal'}
                size={'large'}
                open={openIbanPopup}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={{ iban: editedIban, fatherName: customerData.editedFathersName, birthDate: customerData.editedBirthdate ? moment(customerData.editedBirthdate).format('YYYY-MM-DD') : customerData.editedBirthdate, email: customerData.editedEmail, address: customerData.editedAddress, city: customerData.editedCity, description: customerData.editedDescription }}
                    onSubmit={(values) => {
                        editIbanHandler(values);
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email('Invalid email')
                            .required('This field is required.')
                            .trim(),
                        birthDate: Yup.string()
                            .required('This field is required.')
                            .trim()
                            .nullable(),
                        address: Yup.string()
                            .required('This field is required.')
                            .trim(),
                        city: Yup.string()
                            .required('This field is required.')
                            .trim(),
                        fatherName: Yup.string()
                            .required('This field is required.')
                            .trim(),
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit, isValid }) => (
                        <React.Fragment>
                            <Modal.Header>Edit IBAN</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Iban" type='text' name='iban' placeholder='Iban' value={editedIban} onChange={(e) => {
                                                setIban(e.target.value);
                                            }} onBlur={handleBlur} />
                                            {error ? <span className='formik-error-msg'>{error}</span> : null}
                                        </Form.Field>
                                        <Form.Field style={{ paddingTop: 22 }}>
                                            <Button loading={loading} primary onClick={syncIbanHandler} style={{ width: '100%', height: 35 }}>
                                                SYNC
                                            </Button>
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Father Name" type='text' name='fatherName' placeholder='Father Name' value={values.fatherName} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.fatherName && touched.fatherName ? (
                                                <span className='formik-error-msg'>{errors.fatherName}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Input fluid label="Full Name" type='text' name='fullName' placeholder='Full Name' value={customerData.editedName} readOnly />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid label="Personal Number" type='text' name='personalNumber' placeholder='Personal Number' value={customerData.editedPersonalNumber} readOnly />
                                        <Form.Input fluid label="Customer Bank ID" type='text' name='customerBankId' placeholder='Customer Bank ID' value={customerData.editedCustomerBankID} readOnly />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid label="Customer Phone Number" type='text' name='phoneNumber' placeholder='Phone Number' value={customerData.editedMsisdn} readOnly />
                                        <Form.Field>
                                            <Form.Input fluid label="Customer Birth Date" type='date' name='birthDate' placeholder='Customer Birth Date' value={values.birthDate} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.birthDate && touched.birthDate ? (
                                                <span className='formik-error-msg'>{errors.birthDate}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Address" type='text' name='address' placeholder='Address' value={values.address} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.address && touched.address ? (
                                                <span className='formik-error-msg'>{errors.address}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="City" type='text' name='city' placeholder='City' value={values.city} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.city && touched.city ? (
                                                <span className='formik-error-msg'>{errors.city}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Email" type='email' name='email' placeholder='Email' value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.email && touched.email ? (
                                                <span className='formik-error-msg'>{errors.email}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Mobile Operator</label>
                                            <Select placeholder={mnos.map(mno => {
                                                if (selectedMNOID === mno.id) {
                                                    return mno.name;
                                                }
                                            })} onChange={onSelectMNOHandler} options={mnos.map(mno => {
                                                return { key: mno.id, value: mno.name, text: mno.name };
                                            })} style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Bank Branch</label>
                                            <Select placeholder={bankBranch.map(branch => {
                                                if (selectedBankBranch === branch.id) {
                                                    return branch.displayName;
                                                }
                                            })} onChange={onSelectBranchHandler} options={bankBranch.map(branch => {
                                                return { key: branch.id, value: branch.displayName, text: branch.displayName };
                                            })} style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="Account Description" type='text' name='description' placeholder='Account Description' value={values.description} onChange={handleChange} onBlur={handleBlur} />
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative onClick={() => { setOpenIbanPopup(false); }}>
                                    Cancel
                                </Button>
                                <Button disabled={!confirmed} loading={loading} primary onClick={handleSubmit}>
                                    Change Iban
                                </Button>
                            </Modal.Actions>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>

            <Modal
                className={'regular-modal step-2'}
                size={'large'}
                open={openIbanPopupForMerchant}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        iban: editedIban,
                        birthDate: customerData.editedBirthdate ? moment(customerData.editedBirthdate).format('YYYY-MM-DD') : customerData.editedBirthdate,
                        email: customerData.editedEmail,
                        address: customerData.editedAddress,
                        fullName: customerData.editedName,
                        nuis: customerData.editedNuis,
                        msisdn: customerData.editedMsisdn,
                        username: customerData.editedUsername,
                        personalNumber: customerData.editedPersonalNumber,
                        businessName: customerData.editedBusinessName,
                        merchantTradeName: customerData.merchantTradeName
                    }}
                    onSubmit={(values) => {
                        editHandlerForMerchant(values);
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email('Invalid email')
                            .required('This field is required.')
                            .trim(),
                        birthDate: Yup.string()
                            .required('This field is required.')
                            .trim()
                            .nullable(),
                        address: Yup.string()
                            .required('This field is required.')
                            .trim(),
                        fullName: Yup.string()
                            .required('This field is required.')
                            .trim(),
                        nuis: Yup.string()
                            .required('This field is required.')
                            .trim(),
                        msisdn: Yup.string()
                            .required('This field is required.')
                            .trim(),
                        personalNumber: Yup.string()
                            .required('This field is required.')
                            .trim(),
                        businessName: Yup.string()
                            .required('This field is required.')
                            .trim(),
                        username: Yup.string()
                            .required('This field is required.')
                            .trim(),
                            merchantTradeName: Yup.string()
                            .required('This field is required.')
                            .trim()
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <React.Fragment>
                            <Modal.Header style={{ textAlign: 'start' }}>Edit Merchant</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input readOnly fluid label="Merchant Iban" type='text' name='iban' placeholder='Iban' value={editedIban} onChange={(e) => {
                                                setIban(e.target.value);
                                            }} onBlur={handleBlur} />
                                            {error ? <span className='formik-error-msg'>{error}</span> : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="Business Legal Name" type='text' name='businessName' placeholder='Business Name' value={values.businessName} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.businessName && touched.businessName ? (
                                                <span className='formik-error-msg'>{errors.businessName}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Legal.Rep Name" type='text' name='fullName' placeholder='Full Name' value={values.fullName} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.fullName && touched.fullName ? (
                                                <span className='formik-error-msg'>{errors.fullName}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="NUIS" type='text' name='nuis' placeholder='NUIS' value={values.nuis} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.nuis && touched.nuis ? (
                                                <span className='formik-error-msg'>{errors.nuis}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Legal.Rep Personal Number" type='text' name='personalNumber' placeholder='Personal Number' value={values.personalNumber} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.personalNumber && touched.personalNumber ? (
                                                <span className='formik-error-msg'>{errors.personalNumber}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="Legal.Rep MSISDN" type='text' name='msisdn' value={values.msisdn} placeholder='MSISDN' onChange={handleChange} onBlur={handleBlur} />
                                            {errors.msisdn && touched.msisdn ? (
                                                <span className='formik-error-msg'>{errors.msisdn}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Legal.Rep Email" type='email' name='email' placeholder='Email' value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.email && touched.email ? (
                                                <span className='formik-error-msg'>{errors.email}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="Merchant Username" type='text' name='username' placeholder='Username' value={values.username} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.username && touched.username ? (
                                                <span className='formik-error-msg'>{errors.username}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                    <Form.Field>
                                      <label>Merchant Trade Name</label>
                                       <Form.Input type="text" name="merchantTradeName" value={customerData.editedMerchantTradeName || ''} onChange={handleChange} onBlur={handleBlur}/>
                                          {errors.merchantTradeName && touched.merchantTradeName && (
                                           <div className="formik-error-msg">{errors.merchantTradeName}</div>
                                          )}
                                       </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="Legal.Rep Birth Date" type='date' name='birthDate' placeholder='Merchant Birth Date' value={values.birthDate} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.birthDate && touched.birthDate ? (
                                                <span className='formik-error-msg'>{errors.birthDate}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Merchant Address" type='text' name='address' placeholder='Address' value={values.address} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.address && touched.address ? (
                                                <span className='formik-error-msg'>{errors.address}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Legal.Rep Mobile Operator</label>
                                            <Select placeholder={mnos.map(mno => {
                                                if (selectedMNOID === mno.indexId) {
                                                    return mno.name;
                                                }
                                            })} onChange={onSelectMNOHandler} options={mnos.map(mno => {
                                                return { key: mno.indexId, value: mno.name, text: mno.name };
                                            })} style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Bank Branch</label>
                                            <Select placeholder={bankBranch.map(branch => {
                                                if (selectedBankBranch === branch.indexId) {
                                                    return branch.displayName;
                                                }
                                            })} onChange={onSelectBranchHandler} options={bankBranch.map(branch => {
                                                return { key: branch.indexId, value: branch.displayName, text: branch.displayName };
                                            })} style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                        </Form.Field>
                                        <Form.Field>
                                        </Form.Field>
                                    </Form.Group>

                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Merchant Legal Form</label>
                                            <Select placeholder={legalForm.map(legalform => {
                                                if (selectedFormID === legalform.indexId) {
                                                    return legalform.name;
                                                }
                                            })} onChange={onSelectLegalFormHandler} options={legalForm.map(legalform => {
                                                return { key: legalform.indexId, value: legalform.name, text: legalform.name };
                                            })} style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Commission Schema</label>
                                            <Select placeholder={commissionSchema.map(schema => {
                                                if (selectedSchemaID === schema.indexId) {
                                                    return schema.name;
                                                }
                                            })} onChange={onSelectSchemaHandler} options={commissionSchema.map(schema => {
                                                return { key: schema.indexId, value: schema.name, text: schema.name };
                                            })} style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative onClick={() => { setOpenIbanPopupForMerchant(false); }}>
                                    Cancel
                                </Button>
                                <Button loading={loading} primary onClick={handleSubmit}>
                                    Edit Merchant
                                </Button>
                            </Modal.Actions>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>

            <Modal
                className={'large-modal msisdn'}
                size={'large'}
                open={openMsisdnPopup}
            >
                <Formik
                    initialValues={{ fatherName: data.fathersName, birthDate: data.birthdate ? moment(data.birthdate).format('YYYY-MM-DD') : data.birthdate, email: data.email, address: data.address, city: data.city, msisdn: data.msisdn, description: data.description }}
                    onSubmit={values => {
                        editMsisdnHandler(values);
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email('Invalid email')
                            .required('This field is required.')
                            .trim(),
                        birthDate: Yup.string()
                            .required('This field is required.')
                            .trim()
                            .nullable(),
                        msisdn: Yup.string()
                            .required('This field is required.')
                            .trim(),
                        address: Yup.string()
                            .required('This field is required.')
                            .trim(),
                        city: Yup.string()
                            .required('This field is required.')
                            .trim(),
                        fatherName: Yup.string()
                            .required('This field is required.')
                            .trim()
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <React.Fragment>
                            <Modal.Header>Edit Msisdn</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid label="Iban" type='text' name='iban' placeholder='Iban' value={data.iban} readOnly />
                                        <Form.Field>
                                            <Form.Input fluid label="Father Name" type='text' name='fatherName' placeholder='Father Name' value={values.fatherName} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.fatherName && touched.fatherName ? (
                                                <span className='formik-error-msg'>{errors.fatherName}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid label="Full Name" type='text' name='fullName' placeholder='Full Name' value={data.name} readOnly />
                                        <Form.Input fluid label="Personal Number" type='text' name='personalNumber' placeholder='Personal Number' value={data.personalNumber} readOnly />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid label="Customer Bank ID" type='text' name='customerBankId' placeholder='Customer Bank ID' value={data.customerBankId} readOnly />
                                        <Form.Field>
                                            <Form.Input fluid label="Customer Birth Date" type='date' name='birthDate' placeholder='Customer Birth Date' value={values.birthDate} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.birthDate && touched.birthDate ? (
                                                <span className='formik-error-msg'>{errors.birthDate}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="Email" type='email' name='email' placeholder='Email' value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.email && touched.email ? (
                                                <span className='formik-error-msg'>{errors.email}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="Address" type='text' name='address' placeholder='Address' value={values.address} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.address && touched.address ? (
                                                <span className='formik-error-msg'>{errors.address}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid label="City" type='text' name='city' placeholder='City' value={values.city} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.city && touched.city ? (
                                                <span className='formik-error-msg'>{errors.city}</span>
                                            ) : null}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid label="Customer Phone Number" type='text' name='msisdn' placeholder='Phone Number' value={values.msisdn} onChange={handleChange} onBlur={handleBlur} />
                                            {errors.msisdn && touched.msisdn ? (
                                                <span className='formik-error-msg'>{errors.msisdn}</span>
                                            ) : null}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label>Mobile Operator</label>
                                            <Select placeholder={mnos.map(mno => {
                                                if (selectedMNOID === mno.id) {
                                                    return mno.name;
                                                }
                                            })} onChange={onSelectMNOHandler} options={mnos.map(mno => {
                                                return { key: mno.id, value: mno.name, text: mno.name };
                                            })} style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Bank Branch</label>
                                            <Select placeholder={bankBranch.map(branch => {
                                                if (selectedBankBranch === branch.id) {
                                                    return branch.displayName;
                                                }
                                            })} onChange={onSelectBranchHandler} options={bankBranch.map(branch => {
                                                return { key: branch.id, value: branch.displayName, text: branch.displayName };
                                            })} style={{ borderRadius: 0, height: 50, paddingTop: 17 }} />
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid label="Account Description" type='text' name='description' placeholder='Account Description' value={values.description} onChange={handleChange} onBlur={handleBlur} />
                                    </Form.Group>
                                </Form>

                            </Modal.Content>
                            <Modal.Actions>
                                <Button negative onClick={() => { setOpenMsisdnPopup(false) }}>
                                    Cancel
                                </Button>
                                <Button type='button' loading={loading} primary onClick={handleSubmit}>
                                    Change Msisdn
                                </Button>
                            </Modal.Actions>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>

            <Dialog
                open={openChangePassword}
                onCancel={() => { setOpenChangePassword(false) }}
                onConfirm={merchantChangePassword}
                title={'Reset Password'}
                confirmText={'Reset Password'}
                loading={loading}
            ><p>Are you sure to Reset Password for selected merchant?</p></Dialog>
        </React.Fragment>
    );
}

const dropdownStyle = {
    marginLeft: '-58px'
}

const itemStyle = {
    fontSize: '.7rem'
}

TableRow.propTypes = {
    data: PropTypes.object.isRequired,
};

export default withRouter(TableRow);