import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import TableButtons from '../Table/TableButtons';
import { Form, Dropdown, Ref, Select } from 'semantic-ui-react';
import Input from '../UI/Input';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { baseURL } from '../../config.json';
import config from '../../config.json';

class TableFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            merchantCollection: [],
            legalForm: [],
            schema: [],
            qrStatus: null,
            paymentCategory: null,
            orderId: '',
            selectedField: null,
            selectedSchema: null
        }

        this.dropdownItem = React.createRef();
        this.dropdownItemStatus = React.createRef();
        this.dropdownItemPaymentCategory = React.createRef();
        this.dropdownItemMerchantCollection = React.createRef();
        this.clearChannelValue = this.clearChannelValue.bind(this);
        this.clearQrCodeValue = this.clearQrCodeValue.bind(this);
        this.clearPaymentCategoryValue = this.clearPaymentCategoryValue.bind(this);
        this.clearMerchantCollectionValue = this.clearMerchantCollectionValue.bind(this);
    }

    componentDidMount() {
        if (this.props.location.pathname === '/merchant-transactions-report') {
            this.getMerchantCollection();
        }
        if (this.props.location.pathname === '/merchant-management') {
            this.loadLegalForm();
            this.loadSchemaForm();
        }
    }

    clearChannelValue() {
        var element = this.dropdownItem.current.querySelector('[aria-selected="true"]');
        if (element && this.dropdownItem.current.querySelector(".clear")) {
            this.dropdownItem.current.querySelector(".clear").click();
        }
    }

    clearQrCodeValue() {
        var element = this.dropdownItemStatus.current.querySelector('[aria-selected="true"]');
        if (element && this.dropdownItemStatus.current.querySelector(".clear")) {
            this.dropdownItemStatus.current.querySelector(".clear").click();
        }
    }

    clearPaymentCategoryValue() {
        var element = this.dropdownItemPaymentCategory.current.querySelector('[aria-selected="true"]');
        if (element && this.dropdownItemPaymentCategory.current.querySelector(".clear")) {
            this.dropdownItemPaymentCategory.current.querySelector(".clear").click();
        }
    }

    clearMerchantCollectionValue() {
        var element = this.dropdownItemMerchantCollection.current.querySelector('[aria-selected="true"]');
        if (element && this.dropdownItemMerchantCollection.current.querySelector(".clear")) {
            this.dropdownItemMerchantCollection.current.querySelector(".clear").click();
        }
    }

    getMerchantCollection() {
        fetch(`${baseURL}/merchantportal/merchant-collection`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({})
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                const tempMerchants = [];
                data.value.forEach(el => {
                    tempMerchants.push({
                        key: el.indexId,
                        value: el.nuis,
                        text: el.name
                    });
                });
                this.setState({ merchantCollection: tempMerchants });
            })
            .catch((error) => {
                console.log(error);
            })
    }

    loadLegalForm = () => {
        fetch(`${config.baseURL}/admin/legalform/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10000
            })
        })
            .then(res => res.json())
            .then(data => {
                const tempLegal = [];
                data.value.data.forEach((el, index) => {
                    if (index === 0) {
                        tempLegal.push({
                            key: 0,
                            value: null,
                            text: 'Select'
                        });
                    }
                    tempLegal.push({
                        key: el.indexId + 1,
                        value: el.id,
                        text: el.name
                    });
                });
                this.setState({ legalForm: tempLegal });
            })
            .catch(error => {
                console.log(error)
            });
    };

    loadSchemaForm = () => {
        fetch(`${config.baseURL}/admin/commissionschema/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10000
            })
        })
            .then(res => res.json())
            .then(data => {
                const tempSchema = [];
                data.value.data.forEach((el, index) => {
                    if (index === 0) {
                        tempSchema.push({
                            key: 0,
                            value: null,
                            text: 'Select'
                        });
                    }
                    tempSchema.push({
                        key: el.indexId + 1,
                        value: el.id,
                        text: el.name
                    });
                });
                this.setState({ schema: tempSchema });
            })
            .catch(error => {
                console.log(error)
            });
    };


    onResetFilters = () => {
        this.setState({ selectedField: '', selectedSchema: '' })
    };

    render() {
        let content;
        const { pathname } = this.props.location;

        if (pathname !== '/transaction-report' && pathname !== '/merchant-transactions-report') {
            content = (<>
                <Form.Group widths='equal'>
                    {this.props.filterInputs.map((item, index) => {
                        if (index < 5) {
                            if (item.model === 'select') {
                                if (item.qrStatus){
                                    return (
                                        <Form.Field key={index}>
                                            <label>QR Status</label>
                                            <Ref innerRef={this.dropdownItemStatus}>
                                                <Dropdown
                                                    placeholder="QR Status"
                                                    fluid
                                                    selection
                                                    options={item.qrStatus.map(status => {
                                                        return { key: status.key, value: status.value, text: status.text }
                                                    })}
                                                    onChange={(e, data) => { this.props.onQrStatusChange(e, data, index); }}
                                                    style={{ borderRadius: 0, height: 50, paddingTop: 17 }}
                                                    clearable
                                                />
                                            </Ref>
                                        </Form.Field>
                                    );
                                } else if (item.paymentCategory) {
                                    return (
                                        <Form.Field key={index}>
                                            <label>Payment Category</label>
                                            <Ref innerRef={this.dropdownItemPaymentCategory}>
                                                <Dropdown
                                                    placeholder="Payment Category"
                                                    fluid
                                                    selection
                                                    options={item.paymentCategory.map(status => {
                                                        return { key: status.key, value: status.value, text: status.text }
                                                    })}
                                                    onChange={(e, data) => { this.props.onPaymentCategoryChange(e, data, index); }}
                                                    style={{ borderRadius: 0, height: 50, paddingTop: 17 }}
                                                    clearable
                                                />
                                            </Ref>
                                        </Form.Field>
                                    );
                                }
                                else return (
                                    <Form.Field key={index}>
                                        <label>Channels</label>
                                        <Ref innerRef={this.dropdownItem}>
                                            <Dropdown
                                                placeholder="Channels"
                                                fluid
                                                selection
                                                options={item.channels.map(channel => {
                                                    return { key: channel.key, value: channel.value, text: channel.text }
                                                })}
                                                onChange={(e, data) => { this.props.onChannelChange(e, data, index); }}
                                                style={{ borderRadius: 0, height: 50, paddingTop: 17 }}
                                                clearable
                                            />
                                        </Ref>
                                    </Form.Field>
                                );
                            } else {
                                if ((pathname === '/topup-transaction-report' || pathname === '/topup-settlement-report') && item.type === 'datetime-local') {
                                    return (
                                        <Form.Field key={index}>
                                            <label>{item.label}</label>
                                            <Datetime value={item.value.replace('T', ' ')} dateFormat='YYYY-MM-DD' timeFormat='HH:mm:ss' onClose={this.props.onCloseHandler} onChange={(date) => {
                                                this.props.onChangeHandler(date, index)
                                            }}
                                                renderInput={(props) => {
                                                    return <input {...props} value={item.value.replace('T', ' ')} onChange={(e) => this.props.onManualDateChange(e, index)} />
                                                }} />
                                        </Form.Field>
                                    )
                                } else {
                                    return (
                                        <Form.Field>
                                            <Input label={item.label} type={item.type} name={item.label} placeholder={item.placeholder ? item.placeholder : item.label} value={
                                                item.label === 'Start Date' ? item.value.replace('T00:00:00', '') : item.label === 'End Date' ? item.value.replace('T23:59:59', '') : item.value
                                            } onChange={(e) => { this.props.onChangeHandler(e, index) }} />
                                        </Form.Field>
                                    );
                                }
                            }
                        }

                        if (index == 5) {
                            if(item.type === 'text'){
                                return (
                                    <Form.Field key={index}>
                                        <Input label={item.label} type={item.type} name={item.label} placeholder={item.placeholder ? item.placeholder : item.label} value={
                                            item.label === 'Start Date' ? item.value.replace('T00:00:00', '') : item.label === 'End Date' ? item.value.replace('T23:59:59', '') : item.value
                                        } onChange={(e) => { this.props.onChangeHandler(e, index) }} />
                                    </Form.Field>
                                );
                            } else if (item.model === 'select' && item.channels) {
                                return (
                                    <Form.Field key={index}>
                                        <label>Channel</label>
                                        <Ref innerRef={this.dropdownItem}>
                                            <Dropdown
                                                placeholder="Channel"
                                                fluid
                                                selection
                                                options={item.channels.map(channel => {
                                                    return { key: channel.key, value: channel.value, text: channel.text }
                                                })}
                                                onChange={(e, data) => { this.props.onChannelChange(e, data, index); }}
                                                style={{ borderRadius: 0, height: 50, paddingTop: 17 }}
                                                clearable
                                            />
                                        </Ref>
                                    </Form.Field>
                                );
                            }
                        }
                    })}
                </Form.Group>

                <Form.Group widths='1'>
                    {this.props.filterInputs.map((item, index) => {
                        if (index > 5) {
                            if (item.type === 'legalForm') {
                                return (
                                    <Form.Field>
                                        <label>Legal Form</label>
                                        <div style={{ display: 'inline-block' }}>
                                            <Select
                                                placeholder='Select Legal Form'
                                                value={this.state.selectedField}
                                                search
                                                selection
                                                options={this.state.legalForm}
                                                onChange={(e, data) => { this.props.onChangeHandler(e, index, data['value']); this.setState({ selectedField: data['value'] }); }}
                                                style={{ borderRadius: 0, height: 50, paddingTop: 17 }}
                                            />
                                        </div>
                                    </Form.Field>
                                );
                            } else if (item.type === 'commissionSchema') {
                                return (
                                    <Form.Field>
                                        <label>Commission Schema</label>
                                        <div style={{ display: 'inline-block' }}>
                                            <Select
                                                placeholder='Select Commission Schema'
                                                value={this.state.selectedSchema}
                                                search
                                                selection
                                                options={this.state.schema}
                                                onChange={(e, data) => { this.props.onChangeHandler(e, index, data['value']); this.setState({ selectedSchema: data['value'] }); }}
                                                style={{ borderRadius: 0, height: 50, paddingTop: 17 }}
                                            />
                                        </div>
                                    </Form.Field>
                                );
                            }
                            if (item.model === 'select') {
                                return (
                                    <Form.Field key={index}>
                                        <label>Channels</label>
                                        <Ref innerRef={this.dropdownItem}>
                                            <Dropdown
                                                placeholder="Channels"
                                                fluid
                                                selection
                                                options={item.channels.map(channel => {
                                                    return { key: channel.key, value: channel.value, text: channel.text }
                                                })}
                                                onChange={(e, data) => { this.props.onChannelChange(e, data, index); }}
                                                style={{ borderRadius: 0, height: 50, paddingTop: 17 }}
                                                clearable
                                            />
                                        </Ref>
                                    </Form.Field>
                                );
                            } else {
                                if ((pathname === '/topup-transaction-report' || pathname === '/topup-settlement-report') && item.type === 'datetime-local') {
                                    return (
                                        <Form.Field key={index}>
                                            <label>{item.label}</label>
                                            <Datetime value={item.value.replace('T', ' ')} dateFormat='YYYY-MM-DD' timeFormat='HH:mm:ss' onClose={this.props.onCloseHandler} onChange={(date) => {
                                                this.props.onChangeHandler(date, index)
                                            }}
                                                renderInput={(props) => {
                                                    return <input {...props} value={item.value.replace('T', ' ')} onChange={(e) => this.props.onManualDateChange(e, index)} />
                                                }} />
                                        </Form.Field>
                                    )
                                } else {
                                    return (
                                        <Form.Field key={index}>
                                            <Input label={item.label} type={item.type} name={item.label} placeholder={item.placeholder ? item.placeholder : item.label} value={
                                                item.label === 'Start Date' ? item.value.replace('T00:00:00', '') : item.label === 'End Date' ? item.value.replace('T23:59:59', '') : item.value
                                            } onChange={(e) => { this.props.onChangeHandler(e, index) }} />
                                        </Form.Field>
                                    );
                                }
                            }
                        }
                    })}
                </Form.Group>
            </>

            );
        } else {
            content = (
                <React.Fragment>
                    <Form.Group>
                        {this.props.filterInputs.map((item, index) => {
                            if (index == 0 || index == 1) {
                                return (
                                    <Form.Field key={index}>
                                        <Input label={item.label} type={item.type} name={item.label} placeholder={item.placeholder ? item.placeholder : item.label} value={
                                            item.label === 'Start Date' ? item.value.replace('T00:00:00', '') : item.label === 'End Date' ? item.value.replace('T23:59:59', '') : item.value
                                        } onChange={(e) => { this.props.onChangeHandler(e, index) }} />
                                    </Form.Field>
                                );
                            }
                            if (index > 1 && index <= 5) {
                                return (
                                    <Form.Field key={index} style={{ flex: 1 }}>
                                        <Input label={item.label} type={item.type} name={item.label} placeholder={item.placeholder ? item.placeholder : item.label} value={item.value} onChange={(e) => { this.props.onChangeHandler(e, index) }} />
                                    </Form.Field>
                                );
                            }
                        })}
                    </Form.Group>

                    <Form.Group>
                        {this.props.filterInputs.map((item, index) => {
                            if (index > 5) {
                                if (item.type === 'ddl') {
                                    return (
                                        <Form.Field style={{ flex: 1 }} key={index}>
                                            <label>Merchant</label>
                                            <Ref innerRef={this.dropdownItemMerchantCollection}>
                                                <Dropdown
                                                    placeholder='Select Merchant'
                                                    fluid
                                                    search
                                                    selection
                                                    options={this.state.merchantCollection}
                                                    onChange={(e, data) => { this.props.onChangeHandler(e, index, data['value']); }}
                                                    style={{ borderRadius: 0, height: 50, paddingTop: 17 }}
                                                    clearable
                                                />
                                            </Ref>
                                        </Form.Field>
                                    )
                                } else if (item.model === 'select') {
                                    return (
                                        <Form.Field style={{ flex: 1 }} key={index}>
                                            <label>Channels</label>
                                            <Ref innerRef={this.dropdownItem}>
                                                <Dropdown
                                                    placeholder="Channels"
                                                    fluid
                                                    selection
                                                    options={item.channels.map(channel => {
                                                        return { key: channel.key, value: channel.value, text: channel.text }
                                                    })}
                                                    onChange={(e, data) => { this.props.onChannelChange(e, data, index); }}
                                                    style={{ borderRadius: 0, height: 50, paddingTop: 17 }}
                                                    clearable
                                                />
                                            </Ref>
                                        </Form.Field>
                                    );
                                } else {
                                    return (
                                        <Form.Field key={index} style={{ flex: 1 }}>
                                            <Input label={item.label} type={item.type} name={item.label}
                                                placeholder={item.placeholder ? item.placeholder : item.label}
                                                value={item.value} onChange={(e) => {
                                                    this.props.onChangeHandler(e, index);
                                                }} />
                                        </Form.Field>
                                    );
                                }
                            }
                        })}
                    </Form.Group>
                </React.Fragment>
            );
        }

        return (
            <Fragment>
                <Form size='mini'>
                    {content}
                </Form>
                <TableButtons
                    pathname={pathname}
                    clearChannelValue={this.clearChannelValue}
                    clearQrCodeValue={this.clearQrCodeValue}
                    clearPaymentCategoryValue={this.clearPaymentCategoryValue}
                    clearMerchantCollectionValue={this.clearMerchantCollectionValue}
                    refreshHandler={this.props.refreshHandler}
                    resetFilters={() => {
                        this.props.resetFilters();
                        this.onResetFilters();
                    }}
                    loading={this.props.loading}
                />
            </Fragment>
        );
    }
}

export default withRouter(TableFilters);