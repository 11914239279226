import React from 'react';

const Spinner = props => {
    return (
        <div className='spinner-container'>
            <span className="spinner">
                Loading...
            </span>
        </div>
    );
};

export default  Spinner;