import React, { Component } from 'react';
import { Button, Grid } from 'semantic-ui-react';

class TableButtons extends Component {
    resetHandler = () => {
        const { pathname } = this.props;
        this.props.resetFilters();
        if (pathname === '/transaction-report' || pathname === '/subscription-report' || pathname === '/topup-transaction-report') {
            this.props.clearChannelValue();
        }
        if (pathname === '/merchant-qr-code-report') {
            this.props.clearChannelValue();
            this.props.clearQrCodeValue();
        }
        if (pathname === '/p2b-payments-settlement-report') {
            this.props.clearPaymentCategoryValue();
        }
        if (pathname === '/merchant-transactions-report') {
            this.props.clearMerchantCollectionValue();
        }
    }

    render() {
        return (
            <Grid>
                <Grid.Column tablet={16} computer={10}>
                    <Button size='mini' loading={this.props.loading} primary onClick={this.props.refreshHandler}><i className="fas fa-sync"></i> Refresh Table</Button>
                </Grid.Column>
                <Grid.Column tablet={16} computer={6} className="buttons-group-col-2">
                    <Button.Group size='mini'>
                        <Button onClick={this.resetHandler}>Reset</Button>
                        <Button.Or />
                        <Button primary onClick={() => {
                            this.props.refreshHandler();
                        }}>Filter Now</Button>
                    </Button.Group>
                </Grid.Column>
            </Grid>
        );
    }
}

export default TableButtons;